import AbstractApi from "./AbstractApi";

class SitesApi extends AbstractApi  {

  // Sites
  createMethod = 'dashboard/sites/create';
  getFilterMethod = 'dashboard/sites/get';
  getMethod = 'dashboard/sites/{:id}/get';
  updateMethod = 'dashboard/sites/{:id}/update';
  deleteMethod = 'dashboard/sites/{:id}/delete';
  metaMethod = 'dashboard/sites/meta';

  /*--------------------------------------------------------------------------*/
  /* Sites */

  async create(
    name,
    defaultPrice,
    defaultQuantity,
    defaultExternalId,
    type = 'base',
    formula = false,
    crmApiKey = false,
    crmBaseUri = false,
    crmOrderType = false,
    crmOrderMethod = false,
    crmShop = false,
    crmBuyerId = false,
    upsells = [],
    country = '',
    language = '',
    internalName = '',
    url = '',
    giftSum = false,
    giftId = '',
    freeDeliverySum = false,
    freeDeliveryId = '',
    botApprovementDiscount = false,
    approvementOptions = 0,
    mode = 'sync'
  ) {

    this.requestMethod = this.createMethod;

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);
    this.requestData.append('name', name);

    this.requestData.append('default[price]', defaultPrice);
    this.requestData.append('default[quantity]', defaultQuantity);
    this.requestData.append('default[external_id]', defaultExternalId);

    this.requestData.append('type', type);

    if(formula !== false && type !== 'base'){

      for (var i = 0; i < formula.length; i++) {
        this.requestData.append('formula[' + i + '][name]', formula[i].name);

        for (var j = 0; j < formula[i].options.length; j++) {
          if(type === 'calculator'){
            this.requestData.append('formula[' + i + '][options][' + j + '][from]', formula[i].options[j].from);
            this.requestData.append('formula[' + i + '][options][' + j + '][to]', formula[i].options[j].to);

            this.requestData.append('formula[' + i + '][options][' + j + '][display][old]', formula[i].options[j].display.old);
            this.requestData.append('formula[' + i + '][options][' + j + '][display][new]', formula[i].options[j].display.new);
            this.requestData.append('formula[' + i + '][options][' + j + '][display][discount]', formula[i].options[j].display.discount);

            this.requestData.append('formula[' + i + '][options][' + j + '][price]', formula[i].options[j].price);
            this.requestData.append('formula[' + i + '][options][' + j + '][price_crm]', formula[i].options[j].price_crm);
            this.requestData.append('formula[' + i + '][options][' + j + '][devivery]', formula[i].options[j].devivery);
            this.requestData.append('formula[' + i + '][options][' + j + '][devivery_crm]', formula[i].options[j].devivery_crm);

            this.requestData.append('formula[' + i + '][options][' + j + '][external_id]', formula[i].options[j].external_id);

          } else if(type === 'variations'){
            this.requestData.append('formula[' + i + '][options][' + j + '][price]', formula[i].options[j].price);
            this.requestData.append('formula[' + i + '][options][' + j + '][quantity]', formula[i].options[j].quantity);
            this.requestData.append('formula[' + i + '][options][' + j + '][external_id]', formula[i].options[j].external_id);
          }
        }
      }

    }

    if(crmApiKey !== false){
      this.requestData.append('crm[api_key]', crmApiKey);
    }

    if(crmBaseUri !== false){
      this.requestData.append('crm[base_uri]', crmBaseUri);
    }

    if(crmOrderType !== false){
      this.requestData.append('crm[order_type]', crmOrderType);
    }

    if(crmOrderMethod !== false){
      this.requestData.append('crm[order_method]', crmOrderMethod);
    }

    if(crmShop !== false){
      this.requestData.append('crm[shop]', crmShop);
    }

    if(crmBuyerId !== false){
      this.requestData.append('crm[buyer_id]', crmBuyerId);
    }

    upsells.forEach((item, i) => {
      this.requestData.append(String('upsells[').concat(i).concat('][type]'), item.type);
      this.requestData.append(String('upsells[').concat(i).concat('][id]'), item.id);
    });

    this.requestData.append('country', country);
    this.requestData.append('language', language);

    this.requestData.append('internal_name', internalName);
    this.requestData.append('url', url);

    if(giftSum != false){
      this.requestData.append('gift[sum]', giftSum);
    }

    if(giftId != false){
      this.requestData.append('gift[id]', giftId);
    }

    if(freeDeliverySum != false){
      this.requestData.append('free_delivery[sum]', freeDeliverySum);
    }

    if(freeDeliveryId != false){
      this.requestData.append('free_delivery[id]', freeDeliveryId);
    }

    if(botApprovementDiscount !== false){
      this.requestData.append('bot_approvement_discount', botApprovementDiscount);
    }

    if(approvementOptions !== false){
      this.requestData.append('approvement_options', approvementOptions);
    }

    if(mode != false){
      this.requestData.append('mode', mode);
    }

    return await this.request();
  }


  async get(
    id = false,
    filterOrderBy = 'name',
    filterOrder = 'asc',
    filterSearch = false,
    filterType = false,
    filterCrmBuyerId = false,
    filterUpsellsCategoryId = false,
    paginationPage = 1,
    paginationLimit = 50
  ) {

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    if(id){
      this.requestMethod = this.getMethod.replace('{:id}', id);
    } else {
      this.requestMethod = this.getFilterMethod;

      this.requestData.append('filter[order_by]', filterOrderBy);
      this.requestData.append('filter[order]', filterOrder);

      if(filterSearch){
        this.requestData.append('filter[search]', filterSearch);
      }

      if(filterType != false){
        this.requestData.append('filter[type]', filterType);
      }

      if(filterCrmBuyerId != false){
        this.requestData.append('filter[crm_buyer_id]', filterCrmBuyerId);
      }

      if(parseInt(filterUpsellsCategoryId) > 0){
        this.requestData.append('filter[upsells_category_id]', filterUpsellsCategoryId);
      }

      this.requestData.append('pagination[page]', paginationPage);
      this.requestData.append('pagination[limit]', paginationLimit);
    }

    return await this.request();
  }


  async update(
    id,
    name,
    defaultPrice,
    defaultQuantity,
    defaultExternalId,
    type = false,
    formula = false,
    crmApiKey = false,
    crmBaseUri = false,
    crmOrderType = false,
    crmOrderMethod = false,
    crmShop = false,
    crmBuyerId = false,
    upsells = false,
    country = false,
    language = false,
    internalName = false,
    url = false,
    giftSum = false,
    giftId = false,
    freeDeliverySum = false,
    freeDeliveryId = false,
    botApprovementDiscount = false,
    approvementOptions = false,
    mode = false
  ) {

    this.requestMethod = this.updateMethod.replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);
    this.requestData.append('name', name);

    this.requestData.append('default[price]', defaultPrice);
    this.requestData.append('default[quantity]', defaultQuantity);
    this.requestData.append('default[external_id]', defaultExternalId);

    if(type !== false){
      this.requestData.append('type', type);
    }

    if(formula !== false && type !== false && type !== 'base'){

      for (var i = 0; i < formula.length; i++) {
        this.requestData.append('formula[' + i + '][name]', formula[i].name);

        for (var j = 0; j < formula[i].options.length; j++) {
          if(type === 'calculator'){
            this.requestData.append('formula[' + i + '][options][' + j + '][from]', formula[i].options[j].from);
            this.requestData.append('formula[' + i + '][options][' + j + '][to]', formula[i].options[j].to);

            this.requestData.append('formula[' + i + '][options][' + j + '][display][old]', formula[i].options[j].display.old);
            this.requestData.append('formula[' + i + '][options][' + j + '][display][new]', formula[i].options[j].display.new);
            this.requestData.append('formula[' + i + '][options][' + j + '][display][discount]', formula[i].options[j].display.discount);

            this.requestData.append('formula[' + i + '][options][' + j + '][price]', formula[i].options[j].price);
            this.requestData.append('formula[' + i + '][options][' + j + '][price_crm]', formula[i].options[j].price_crm);
            this.requestData.append('formula[' + i + '][options][' + j + '][devivery]', formula[i].options[j].devivery);
            this.requestData.append('formula[' + i + '][options][' + j + '][devivery_crm]', formula[i].options[j].devivery_crm);

            this.requestData.append('formula[' + i + '][options][' + j + '][external_id]', formula[i].options[j].external_id);

          } else if(type === 'variations'){
            this.requestData.append('formula[' + i + '][options][' + j + '][price]', formula[i].options[j].price);
            this.requestData.append('formula[' + i + '][options][' + j + '][quantity]', formula[i].options[j].quantity);
            this.requestData.append('formula[' + i + '][options][' + j + '][external_id]', formula[i].options[j].external_id);
          }
        }
      }

    }

    if(crmApiKey !== false){
      this.requestData.append('crm[api_key]', crmApiKey);
    }

    if(crmBaseUri !== false){
      this.requestData.append('crm[base_uri]', crmBaseUri);
    }

    if(crmOrderType !== false){
      this.requestData.append('crm[order_type]', crmOrderType);
    }

    if(crmOrderMethod !== false){
      this.requestData.append('crm[order_method]', crmOrderMethod);
    }

    if(crmShop !== false){
      this.requestData.append('crm[shop]', crmShop);
    }

    if(crmBuyerId !== false){
      this.requestData.append('crm[buyer_id]', crmBuyerId);
    }

    if(upsells !== false){
      upsells.forEach((item, i) => {
        this.requestData.append(String('upsells[').concat(i).concat('][type]'), item.type);
        this.requestData.append(String('upsells[').concat(i).concat('][id]'), item.id);
      });
    }

    if(country !== false){
      this.requestData.append('country', country);
    }

    if(language !== false){
      this.requestData.append('language', language);
    }

    if(internalName !== false){
      this.requestData.append('internal_name', internalName);
    }

    if(url !== false){
      this.requestData.append('url', url);
    }

    if(giftSum != false || giftSum === ''){
      this.requestData.append('gift[sum]', giftSum);
    }

    if(giftId != false){
      this.requestData.append('gift[id]', giftId);
    }

    if(freeDeliverySum != false || freeDeliverySum === ''){
      this.requestData.append('free_delivery[sum]', freeDeliverySum);
    }

    if(freeDeliveryId != false){
      this.requestData.append('free_delivery[id]', freeDeliveryId);
    }

    if(botApprovementDiscount !== false){
      this.requestData.append('bot_approvement_discount', botApprovementDiscount);
    }

    if(approvementOptions !== false){
      this.requestData.append('approvement_options', approvementOptions);
    }


    if(mode != false){
      this.requestData.append('mode', mode);
    }


    return await this.request();
  }


  async delete(id) {

    this.requestMethod = this.deleteMethod.replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    return await this.request();
  }


  async meta(id) {

    this.requestMethod = this.metaMethod;

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    return await this.request();
  }

}

export default SitesApi;
