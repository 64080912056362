import { useState, useEffect, useContext } from 'react';
import { NavLink, useParams, useNavigate } from "react-router-dom";

import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import Breadcrumb from '../../components/breadcrumbs/Breadcrumb';

import UpsellAttachments from '../../components/upsells/upsell/UpsellAttachments';
import UpsellCategories from '../../components/upsells/upsell/UpsellCategories';

import UpsellTitle from '../../components/upsells/upsell/UpsellTitle';
import UpsellDescription from '../../components/upsells/upsell/UpsellDescription';

import { NotificationsContext } from '../../components/app/contexts/NotificationsContext';
import { ApiContext } from '../../components/app/contexts/ApiContext';
import { RoleContext } from '../../components/app/contexts/RoleContext';

import { roundFloat } from '../../components/app/Functions'

const statusesMap = {
  0: {
    'text': 'Нективен',
    'color': 'red'
  },
  1: {
    'text': 'Активен',
    'color': 'green'
  }
};

const headings = {
  'create': 'Создать товар допродаж',
  'update': 'Редактировать товар допродаж',
}

const roundPrecision = 100000;

function Upsell(props) {

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);
  const role = useContext(RoleContext);

  const [name, setName] = useState('');
  const [status, setStatus] = useState(1);
  const [upsellsCategories, setUpsellsCategories] = useState([]);
  const [upsellsCategoriesMeta, setUpsellsCategoriesMeta] = useState([]);

  const [priority, setPriority] = useState(1);
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [externalId, setExternalId] = useState('');

  const [title, setTitle] = useState('');
  const [priceOld, setPriceOld] = useState('');
  const [discount, setDiscount] = useState('');
  const [priceNew, setPriceNew] = useState('');
  const [description, setDescription] = useState('');
  const [stockText, setStockText] = useState('');
  const [stock, setStock] = useState('');

  const [created, setCreated] = useState(0);
  const [modified, setModified] = useState(0);

  const [attachments, setAttachments] = useState([]);
  const [attachmentsInitial, setAttachmentsInitial] = useState([]);

  const params = useParams();
  const upsellId = params.upsellId;

  const navigate = useNavigate();

  const heading = upsellId > 0 ? (<>{headings.update} - <b>{name}</b></>) : headings.create;


  async function getData(){
    var data = await api.upsells.get(upsellId);

    if(data.status !== true){
      window.location.href = '/';
    } else {

      setName(data.data.name);
      const statusInt = data.data.status ? 1 : 0;
      setStatus(statusInt);
      setUpsellsCategories(data.data.upsells_categories);

      setPriority(data.data.priority);
      setPrice(roundFloat(data.data.price, roundPrecision));
      setQuantity(data.data.quantity);
      setExternalId(data.data.external_id);

      setTitle(data.data.blocks.title);
      setPriceOld(data.data.blocks.price_old);
      setDiscount(data.data.blocks.discount);
      setPriceNew(data.data.blocks.price_new);
      setDescription(data.data.blocks.description);
      setStockText(data.data.blocks.stock_text);
      setStock(data.data.blocks.stock);

      setCreated(data.data.created);
      setModified(data.data.modified);
    }
  }


  async function getAttachments(curUpsellId = false){

    const tempUpsellId = curUpsellId != false ? curUpsellId : upsellId;
    var data = await api.upsell.images.get(tempUpsellId);

    if(data.status == true){
      setAttachments(JSON.parse(JSON.stringify(data.data)));
      setAttachmentsInitial(JSON.parse(JSON.stringify(data.data)));
    }
  }

  async function getCategoriesMeta(){
    var data = await api.upsells_categories.get(false, 'name', 'asc', false, 1, 100);

    if(data.status == true){
      setUpsellsCategoriesMeta(data.data);
    }
  }


  useEffect( () => {

    getCategoriesMeta();

    if(upsellId > 0){
      getData();
      getAttachments();
    }

  }, []);

  const getDigits = (number) => {
    const matches = String(number).match(/\d+/g);
    return parseInt(matches.join(''));
  };

  const calculateDiscount = (oldPrice, newPrice, template = '-{discount}%') => {

    const diff = oldPrice - newPrice;
    const discount = Math.ceil((diff / oldPrice) * 100);
    console.log('Diff', diff);
    console.log('Discount', discount);

    return template.replace('{discount}', discount);
  };

  const updateDiscount = (oldPrice, newPrice) => {

    if(!oldPrice || !newPrice){
      return false;
    }

    const oldPriceValidated = getDigits(oldPrice);
    const newPriceValidated = getDigits(newPrice);

    console.log('Old price', oldPriceValidated);
    console.log('New price', newPriceValidated);

    if(oldPriceValidated > newPriceValidated){
      const newDiscount = calculateDiscount(oldPriceValidated, newPriceValidated);
      if(newDiscount){
        setDiscount(newDiscount);
      }
    }

  };


  const handleInputChange = async (e) => {
    const {id, value} = e.target;

    if(id === 'name'){
      setName(value);
    }

    if(id === 'status'){
      setStatus(value);
    }

    if(id === 'priority'){
      setPriority(value);
    }

    if(id === 'price'){
      setPrice(value);
    }

    if(id === 'quantity'){
      setQuantity(value);
      recalculatePrice(priceNew, value);
    }

    if(id === 'externalId'){
      setExternalId(value);
    }

    if(id === 'priceOld'){
      setPriceOld(value);
      updateDiscount(value, priceNew);
    }

    if(id === 'discount'){
      setDiscount(value);
    }

    if(id === 'priceNew'){
      setPriceNew(value);
      updateDiscount(priceOld, value);
      recalculatePrice(value, quantity);
    }

    if(id === 'stockText'){
      setStockText(value);
    }

    if(id === 'stock'){
      setStock(value);
    }

  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    if(upsellId > 0){

      const response = await api.upsells.update(
        upsellId,
        name,
        status,
        upsellsCategories,
        priority,
        price,
        quantity,
        externalId,
        title,
        priceOld,
        discount,
        priceNew,
        description,
        stockText,
        stock
      );

      if( response && response.status == true ){
        notifications.upsells.notification('upsell_updated');

        await handleAttachments(upsellId);

      } else {
        notifications.upsells.notification(response.error);
      }
    } else {
      const response = await api.upsells.create(
        name,
        status,
        upsellsCategories,
        priority,
        price,
        quantity,
        externalId,
        title,
        priceOld,
        discount,
        priceNew,
        description,
        stockText,
        stock
      );

      if( response && response.status === true ){
        notifications.upsells.notification('upsell_created');

        await handleAttachments(response.id);

        setTimeout(function () {
          navigate(['', 'upsells', response.id].join('/'))
        }, 2500);

      } else {
        notifications.upsells.notification(response.error);
      }
    }

  }


  const handleAttachments = async (curUpsellId) => {

    // console.log('attachments initial', attachmentsInitial);
    // console.log('attachments', attachments);


    var created = [];
    var changed = [];
    var deleted = [];

    for (var j = 0; j < attachments.length; j++) {

      if(attachments[j]['id'] == false || attachments[j]['id'] === -1){
        created.push(attachments[j]);
      }
    }

    for (var i = 0; i < attachmentsInitial.length; i++) {

      var curItem = false;

      for (var j = 0; j < attachments.length; j++) {

        if(attachmentsInitial[i]['id'] == attachments[j]['id']){
          curItem = attachments[j];
        }
      }

      if(curItem === false){
        deleted.push(attachmentsInitial[i]);
      } else {
        const sortCheck = curItem['sort'] != attachmentsInitial[i]['sort'];
        const fileCheck = Object.keys(curItem).indexOf('file') != -1 && curItem['file'] != false;
        const commentCheck = curItem['comment'] != attachmentsInitial[i]['comment'];

        if(sortCheck || fileCheck || commentCheck){
          changed.push(curItem);
        }
      }
    }

    for (var i = 0; i < deleted.length; i++) {
      const imageResponse = await api.upsell.images.delete(
        curUpsellId,
        deleted[i].id
      );

      if(imageResponse && imageResponse.status == true){
        notifications.upsell.images.notification('upsell_image_deleted');
      }
    }

    for (var i = 0; i < created.length; i++) {
      const imageResponse = await api.upsell.images.create(
        curUpsellId,
        created[i].sort,
        created[i].file,
        created[i].comment
      );

      if(imageResponse && imageResponse.status == true){
        notifications.upsell.images.notification('upsell_image_created');
      }
    }

    for (var i = 0; i < changed.length; i++) {

      const file = Object.keys(changed[i]).indexOf('file') != -1 && changed[i].file != false ? changed[i].file : false;

      const imageResponse = await api.upsell.images.update(
        curUpsellId,
        changed[i].id,
        changed[i].sort,
        file,
        changed[i].comment
      );

      if(imageResponse && imageResponse.status == true){
        notifications.upsell.images.notification('upsell_image_updated');
      }
    }

    await getAttachments(curUpsellId);

  };


  function renderStatusOptions(){
    var options = [];

    Object.keys(statusesMap).forEach((item, i) => {
      options.push(
        <option key={"status-" + i} value={item} style={{color: statusesMap[item]['color']}}>{statusesMap[item]['text']}</option>
      );
    });

    return options;
  }


  function renderStatusColor(){
    const statusInt = parseInt(status) ? 1 : 0;
    return statusesMap[statusInt]['color'];
  }


  const recalculatePrice = (priceNew, quantity, divider = 1000) => {
    var priceTemp = roundFloat(getDigits(priceNew) / quantity / divider, roundPrecision);
    setPrice(priceTemp);
  }


  return (
    <div className="uk-container">

      <Breadcrumbs>
        <Breadcrumb><NavLink to="/">Панель администратора</NavLink></Breadcrumb>
        <Breadcrumb><NavLink to="/upsells">Товары допродаж</NavLink></Breadcrumb>
        <Breadcrumb><span>{heading}</span></Breadcrumb>
      </Breadcrumbs>

      <h1 className="uk-heading-line uk-text-center">
        <span>{heading}</span>
      </h1>

      <h1 className="uk-heading-divider"></h1>

      <div className="uk-width-1-1">
        <div className="uk-text-left">
          <h2>Галерея товара допродаж:</h2>
        </div>
        <UpsellAttachments
          attachments={[attachments, setAttachments]}
        />
        <hr />
      </div>

      <form id="editForm" className="uk-form-vertical uk-grid-small" data-uk-grid onSubmit={handleSubmit}>

        <div className="uk-width-1-1">
          <div className="uk-text-left">
            <h2>Товар допродаж:</h2>
          </div>
        </div>

        <div className="uk-width-3-5">

          <div className="uk-grid-small" data-uk-grid>

            <div className="uk-width-3-5" id="nameBox">
              <label className="uk-form-label" htmlFor="name">Название (внутренний идентификатор):</label>
              <div className="uk-form-controls">
                <input
                  id="name"
                  className="uk-input"
                  name="name"
                  type="text"
                  onChange={handleInputChange}
                  value={name}
                  required={true}
                />
              </div>
            </div>

            <div className="uk-width-1-5" id="priorityBox">
              <label className="uk-form-label" htmlFor="priority">Приоритет:</label>
              <div className="uk-form-controls">
                <input
                  id="priority"
                  className="uk-input"
                  name="priority"
                  type="number"
                  min="0"
                  placeholder="1"
                  step="1"
                  onChange={handleInputChange}
                  value={priority}
                  required={true}
                />
              </div>
            </div>

            <div className="uk-width-1-5" id="statusBox">
              <label className="uk-form-label" htmlFor="status">Статус:</label>
              <div className="uk-form-controls">
                <select
                  id="status"
                  className="uk-select"
                  name="status"
                  onChange={handleInputChange}
                  value={status}
                  required={true}
                  style={{color: renderStatusColor()}}
                >
                  {renderStatusOptions()}
                </select>
              </div>
            </div>

            <div className="uk-width-1-3" id="priceBox">
              <label className="uk-form-label" htmlFor="price">Цена:</label>
              <div className="uk-form-controls">
                <input
                  id="price"
                  className="uk-input"
                  name="price"
                  type="number"
                  min="1"
                  step="0.00001"
                  placeholder="0"
                  onChange={handleInputChange}
                  value={price}
                  required={true}
                  readOnly={true}
                />
              </div>
            </div>

            <div className="uk-width-1-3" id="quantityBox">
              <label className="uk-form-label" htmlFor="quantity">Количество:</label>
              <div className="uk-form-controls">
                <input
                  id="quantity"
                  className="uk-input"
                  name="quantity"
                  type="number"
                  min="1"
                  placeholder="1"
                  onChange={handleInputChange}
                  value={quantity}
                  required={true}
                />
              </div>
            </div>

            <div className="uk-width-1-3" id="externalIdBox">
              <label className="uk-form-label" htmlFor="externalId">Внешний ID:</label>
              <div className="uk-form-controls">
                <input
                  id="externalId"
                  className="uk-input"
                  name="externalId"
                  type="text"
                  placeholder="crm-product-id"
                  onChange={handleInputChange}
                  value={externalId}
                  required={true}
                />
              </div>
            </div>

            <div className="uk-width-1-1">
              <hr />
            </div>

            <UpsellTitle
              title={[title, setTitle]}
            />

            <div className="uk-width-1-3" id="priceOldBox">
              <label className="uk-form-label" htmlFor="priceOld">Старая цена:</label>
              <div className="uk-form-controls">
                <input
                  id="priceOld"
                  className="uk-input"
                  name="priceOld"
                  type="text"
                  onChange={handleInputChange}
                  value={priceOld}
                />
              </div>
            </div>

            <div className="uk-width-1-3" id="discountBox">
              <label className="uk-form-label" htmlFor="discount">Скидка:</label>
              <div className="uk-form-controls">
                <input
                  id="discount"
                  className="uk-input"
                  name="discount"
                  type="text"
                  onChange={handleInputChange}
                  value={discount}
                />
              </div>
            </div>

            <div className="uk-width-1-3" id="priceNewBox">
              <label className="uk-form-label" htmlFor="priceNew">Новая цена:</label>
              <div className="uk-form-controls">
                <input
                  id="priceNew"
                  className="uk-input"
                  name="priceNew"
                  type="text"
                  onChange={handleInputChange}
                  value={priceNew}
                  required={true}
                />
              </div>
            </div>


            <UpsellDescription
              description={[description, setDescription]}
            />

            <div className="uk-width-2-3" id="stockTextBox">
              <label className="uk-form-label" htmlFor="stockText">Текст остатка:</label>
              <div className="uk-form-controls">
                <input
                  id="stockText"
                  className="uk-input"
                  name="stockText"
                  type="text"
                  onChange={handleInputChange}
                  value={stockText}
                />
              </div>
            </div>

            <div className="uk-width-1-3" id="stockBox">
              <label className="uk-form-label" htmlFor="stock">Остаток:</label>
              <div className="uk-form-controls">
                <input
                  id="stock"
                  className="uk-input"
                  name="stock"
                  type="text"
                  onChange={handleInputChange}
                  value={stock}
                />
              </div>
            </div>

          </div>

        </div>


        <div className="uk-width-2-5">
          <UpsellCategories
            categories={[upsellsCategories, setUpsellsCategories]}
            categoriesMeta={upsellsCategoriesMeta}
          />
        </div>

        <div className="uk-width-1-1">
          <button id='submitButton' type="submit" className="uk-button uk-button-primary uk-align-center custom-button-success" onSubmit={handleSubmit}>Сохранить</button>
        </div>

      </form>


    </div>

  )
}

export default Upsell;
