import { useState, useEffect, useContext } from 'react';
import { NavLink, Link, useParams, useSearchParams, useNavigate } from "react-router-dom";

import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import Breadcrumb from '../../components/breadcrumbs/Breadcrumb';

import Upsells from '../../components/sites/upsells/Upsells';
import Variations from '../../components/sites/formulas/Variations';

import FilesModalScreen from '../../components/sites/modals/FilesModalScreen';

import { NotificationsContext } from '../../components/app/contexts/NotificationsContext';
import { ApiContext } from '../../components/app/contexts/ApiContext';
import { RoleContext } from '../../components/app/contexts/RoleContext';

import { roundFloat } from '../../components/app/Functions';

const typesMap = {
  'base': 'Один товар',
  'calculator': 'Калькулятор',
  'variations': 'Вариации'
};

const headings = {
  'create': 'Создать сайт',
  'update': 'Редактировать сайт',
}

const roundPrecision = 100000;

function Site(props) {

  const notifications = useContext(NotificationsContext);
  const api = useContext(ApiContext);
  const role = useContext(RoleContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const [name, setName] = useState('');
  const [defaultPrice, setDefaultPrice] = useState('');
  const [defaultQuantity, setDefaultQuantity] = useState('');
  const [defaultExternalId, setDefaultExternalId] = useState('');

  const [type, setType] = useState('base');
  const [formula, setFormula] = useState([]);

  const [crmApiKey, setCrmApiKey] = useState('');
  const [crmBaseUri, setCrmBaseUri] = useState('');
  const [crmOrderType, setCrmOrderType] = useState('');
  const [crmOrderMethod, setCrmOrderMethod] = useState('');
  const [crmShop, setCrmShop] = useState('');
  const [crmBuyerId, setCrmBuyerId] = useState('');
  const [upsells, setUpsells] = useState([]);
  const [country, setCountry] = useState('UZ');
  const [language, setLanguage] = useState('RU');
  const [internalName, setInternalName] = useState('');
  const [url, setUrl] = useState('');
  const [giftSum, setGiftSum] = useState('');
  const [giftId, setGiftId] = useState('');
  const [freeDeliverySum, setFreeDeliverySum] = useState('');
  const [freeDeliveryId, setFreeDeliveryId] = useState('');
  const [botApprovementDiscount, setBotApprovementDiscount] = useState('');
  const [approvementOptions, setApprovementOptions] = useState(0);
  const [mode, setMode] = useState('async');
  const [created, setCreated] = useState(0);
  const [modified, setModified] = useState(0);

  const [crmBuyerIdsMeta, setCrmBuyerIdsMeta] = useState([]);

  const [modal, setModal] = useState([]);

  const params = useParams();
  const siteId = params.siteId;

  const navigate = useNavigate();

  const heading = siteId > 0 ? (<>{headings.update} - <b>{name}</b></>) : headings.create;


  async function getData(){
    var data = await api.sites.get(siteId);

    if(data.status !== true){
      window.location.href = '/';
    } else {

      setName(data.data.name);
      setDefaultPrice(roundFloat(data.data.default.price, roundPrecision));
      setDefaultQuantity(data.data.default.quantity);
      setDefaultExternalId(data.data.default.external_id);

      setType(data.data.type);
      setFormula(data.data.formula);

      setCrmApiKey(data.data.crm.api_key);
      setCrmBaseUri(data.data.crm.base_uri);
      setCrmOrderType(data.data.crm.order_type);
      setCrmOrderMethod(data.data.crm.order_method);
      setCrmShop(data.data.crm.shop);
      setCrmBuyerId(data.data.crm.buyer_id);

      setUpsells(data.data.upsells);

      setCountry(data.data.country);
      setLanguage(data.data.language);

      setInternalName(data.data.internal_name);
      setUrl(data.data.url);

      setGiftSum(roundFloat(data.data.gift.sum, roundPrecision));
      setGiftId(data.data.gift.id);

      setFreeDeliverySum(roundFloat(data.data.free_delivery.sum, roundPrecision));
      setFreeDeliveryId(data.data.free_delivery.id);

      setBotApprovementDiscount(data.data.bot_approvement_discount);
      setApprovementOptions(data.data.approvement_options);

      setMode(data.data.mode);

      setCreated(data.data.created);
      setModified(data.data.modified);

    }
  }


  async function getMeta(){
    var dataFeed = await api.users.meta();

    if(dataFeed.status === true){

      setCrmBuyerIdsMeta(dataFeed.data.buyer_id);
    }
  }


  useEffect( () => {

    getMeta();

    if(siteId > 0){
      getData();
    }

  }, []);


  const openModal = (e) => {
    e.preventDefault();

    setModal(
      <FilesModalScreen
        siteId={siteId}
        modal={[modal, setModal]}
      />
    );

  };


  const handleInputChange = async (e) => {
    const {id, value} = e.target;

    if(id === 'name'){
      setName(value);
    }

    if(id === 'defaultPrice'){
      setDefaultPrice(value);
    }

    if(id === 'defaultQuantity'){
      setDefaultQuantity(value);
    }

    if(id === 'defaultExternalId'){
      setDefaultExternalId(value);
    }

    if(id === 'type'){
      setType(value);
      // const newFormula = transformFormula(type, value);
      const newFormula = [];
      setFormula(newFormula);
    }

    if(id === 'crmApiKey'){
      setCrmApiKey(value);
    }

    if(id === 'crmBaseUri'){
      setCrmBaseUri(value);
    }

    if(id === 'crmOrderType'){
      setCrmOrderType(value);
    }

    if(id === 'crmOrderMethod'){
      setCrmOrderMethod(value);
    }

    if(id === 'crmShop'){
      setCrmShop(value);
    }

    if(id === 'crmBuyerId'){
      setCrmBuyerId(value);
    }

    if(id === 'country'){
      setCountry(value);
    }

    if(id === 'language'){
      setLanguage(value);
    }

    if(id === 'internalName'){
      setInternalName(value);
    }

    if(id === 'url'){
      setUrl(value);
    }

    if(id === 'giftSum'){
      setGiftSum(value);
    }

    if(id === 'giftId'){
      setGiftId(value);
    }

    if(id === 'freeDeliverySum'){
      setFreeDeliverySum(value);
    }

    if(id === 'freeDeliveryId'){
      setFreeDeliveryId(value);
    }

    if(id === 'botApprovementDiscount'){
      setBotApprovementDiscount(
        roundFloat(parseFloat(value) / 100, 100)
      );
    }

    if(id === 'approvementOptions'){
      setApprovementOptions(value);
    }

    if(id === 'mode'){
      setMode(value);
    }

  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    if(siteId > 0){

      var response = await api.sites.update(
        siteId,
        name,
        defaultPrice,
        defaultQuantity,
        defaultExternalId,
        type,
        formula,
        crmApiKey,
        crmBaseUri,
        crmOrderType,
        crmOrderMethod,
        crmShop,
        crmBuyerId,
        upsells,
        country,
        language,
        internalName,
        url,
        giftSum,
        giftId,
        freeDeliverySum,
        freeDeliveryId,
        botApprovementDiscount,
        approvementOptions,
        mode
      );

      if( response && response.status == true ){
        notifications.sites.notification('site_updated');
      } else {
        notifications.sites.notification(response.error);
      }
    } else {
      var response = await api.sites.create(
        name,
        defaultPrice,
        defaultQuantity,
        defaultExternalId,
        type,
        formula,
        crmApiKey,
        crmBaseUri,
        crmOrderType,
        crmOrderMethod,
        crmShop,
        crmBuyerId,
        upsells,
        country,
        language,
        internalName,
        url,
        giftSum,
        giftId,
        freeDeliverySum,
        freeDeliveryId,
        botApprovementDiscount,
        approvementOptions,
        mode
      );

      if( response && response.status === true ){
        notifications.sites.notification('site_created');

        setTimeout(function () {
          navigate(['', 'sites', response.id].join('/'))
        }, 2500);

      } else {
        notifications.sites.notification(response.error);
      }
    }
  }


  function renderTypesOptions(){
    var options = [];
    options.push(
      <option key='type-default' value="">-- Тип --</option>
    );

    Object.keys(typesMap).forEach((item, i) => {
      options.push(
        <option key={'type-' + item} value={item}>{typesMap[item]}</option>
      );
    });

    return options;
  }


  function renderCrmBuyerIdsOptions(){
    var options = [];
    options.push(
      <option key='crm-buyer-id-default' value="">-- Buyer ID --</option>
    );


    Object.keys(crmBuyerIdsMeta).forEach((item, i) => {
      options.push(
        <option key={'crm-buyer-id-' + item} value={item}>{item}</option>
      );
    });

    return options;
  }


  return (
    <div className="uk-container">

      <div className="uk-grid uk-grid-small" data-uk-grid>
        <div className="uk-width-2-3">
          <Breadcrumbs>
            <Breadcrumb><NavLink to="/">Панель администратора</NavLink></Breadcrumb>
            <Breadcrumb><NavLink to="/sites">Сайты</NavLink></Breadcrumb>
            <Breadcrumb><span>{heading}</span></Breadcrumb>
          </Breadcrumbs>
        </div>

        <div className="uk-width-1-3 uk-text-right">
          {(siteId > 0) && (
            <button
              type="button"
              className="uk-button uk-button-default"
              onClick={openModal}
              style={{
                top: '-6px',
                position: 'relative'
              }}
            >
              <span className="uk-icon" data-uk-icon="download"></span> Файлы интеграции
            </button>
          )}
        </div>
      </div>



      <h1 className="uk-heading-line uk-text-center">
        <span>{heading}</span>
      </h1>

      <h1 className="uk-heading-divider"></h1>

      <form id="editForm" className="uk-form-vertical uk-grid-small" data-uk-grid onSubmit={handleSubmit}>

        <div className="uk-width-3-5">

          <div className="uk-grid-small" data-uk-grid>

            <div className="uk-width-2-3">
              <h2 className="uk-text-left">Общие настройки</h2>
            </div>

            <div className="uk-width-2-3" id="nameBox">
              <label className="uk-form-label" htmlFor="name">Идентификатор:</label>
              <div className="uk-form-controls">
                <input
                  id="name"
                  className="uk-input"
                  name="name"
                  type="text"
                  placeholder="site-id"
                  onChange={handleInputChange}
                  value={name}
                  required={true}
                />
              </div>
            </div>

            <div className="uk-width-1-3" id="buyerIdBox">
              <label className="uk-form-label" htmlFor="crmBuyerId">Buyer ID:</label>
              <div className="uk-form-controls">
                {(role == 'user') && (
                  <input
                    id="crmBuyerId"
                    className="uk-input"
                    name="crmBuyerId"
                    type="text"
                    onChange={handleInputChange}
                    value={crmBuyerId}
                    readOnly={true}
                  />
                )}
                {(role == 'owner' || role == 'admin') && (
                  <select
                    id="crmBuyerId"
                    className="uk-select"
                    name="crmBuyerId"
                    onChange={handleInputChange}
                    value={crmBuyerId}
                  >
                    {renderCrmBuyerIdsOptions()}
                  </select>
                )}
              </div>
            </div>

            <div className="uk-width-2-3" id="internalNameBox">
              <label className="uk-form-label" htmlFor="internalName">Название оффера:</label>
              <div className="uk-form-controls">
                <input
                  id="internalName"
                  className="uk-input"
                  name="internalName"
                  type="text"
                  placeholder="Site #1"
                  onChange={handleInputChange}
                  value={internalName}
                />
              </div>
            </div>

            <div className="uk-width-1-3" id="urlBox">
              <label className="uk-form-label" htmlFor="url">Ссылка на сайт:</label>
              <div className="uk-form-controls">
                <input
                  id="url"
                  className="uk-input"
                  name="url"
                  type="text"
                  placeholder="https://example.com"
                  onChange={handleInputChange}
                  value={url}
                />
              </div>
            </div>

            <div className="uk-width-1-1 uk-text-meta">
              <p>Значения цены, количества и внешнего ID товара рекомендовано заполнить для любого типа сайта, чтобы они использовались по умолчанию при возникновении ошибок и заказ попал в CRM.</p>
            </div>

            <div className="uk-width-1-3" id="defaultPriceBox">
              <label className="uk-form-label" htmlFor="defaultPrice">Цена:</label>
              <div className="uk-form-controls">
                <input
                  id="defaultPrice"
                  className="uk-input"
                  name="defaultPrice"
                  type="number"
                  min="0"
                  step="0.00001"
                  placeholder="100"
                  required={true}
                  onChange={handleInputChange}
                  value={defaultPrice}
                />
              </div>
            </div>

            <div className="uk-width-1-3" id="defaultQuantityBox">
              <label className="uk-form-label" htmlFor="defaultQuantity">Количество:</label>
              <div className="uk-form-controls">
                <input
                  id="defaultQuantity"
                  className="uk-input"
                  name="defaultQuantity"
                  type="number"
                  min="1"
                  step="1"
                  placeholder="1"
                  required={true}
                  onChange={handleInputChange}
                  value={defaultQuantity}
                />
              </div>
            </div>

            <div className="uk-width-1-3" id="defaultExternalIdBox">
              <label className="uk-form-label" htmlFor="defaultExternalId">Внешний ID:</label>
              <div className="uk-form-controls">
                <input
                  id="defaultExternalId"
                  className="uk-input"
                  name="defaultExternalId"
                  type="text"
                  placeholder="crm-external-id"
                  required={true}
                  onChange={handleInputChange}
                  value={defaultExternalId}
                />
              </div>
            </div>

            <div className="uk-width-1-1">
              <hr />
            </div>

            <div className="uk-width-1-1 uk-text-meta">
              <p>Вспомогательные поля для интеграции с CRM. Будут использованы вместо значений по умолчанию.</p>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="crmBaseUri">Ссылка на API:</label>
              <div className="uk-form-controls">
                <input
                  id="crmBaseUri"
                  className="uk-input"
                  name="crmBaseUri"
                  type="text"
                  placeholder="https://crm.com/"
                  onChange={handleInputChange}
                  value={crmBaseUri}
                />
              </div>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="crmApiKey">Ключ API:</label>
              <div className="uk-form-controls">
                <input
                  id="crmApiKey"
                  className="uk-input"
                  name="crmApiKey"
                  type="text"
                  placeholder="crm-api-key"
                  onChange={handleInputChange}
                  value={crmApiKey}
                />
              </div>
            </div>

            <div className="uk-width-1-3">
              <label className="uk-form-label" htmlFor="crmShop">Shop:</label>
              <div className="uk-form-controls">
                <input
                  id="crmShop"
                  className="uk-input"
                  name="crmShop"
                  type="text"
                  placeholder="shop"
                  onChange={handleInputChange}
                  value={crmShop}
                />
              </div>
            </div>

            <div className="uk-width-1-3">
              <label className="uk-form-label" htmlFor="crmOrderType">Order type:</label>
              <div className="uk-form-controls">
                <input
                  id="crmOrderType"
                  className="uk-input"
                  name="crmOrderType"
                  type="text"
                  placeholder="eshop-individual"
                  onChange={handleInputChange}
                  value={crmOrderType}
                />
              </div>
            </div>

            <div className="uk-width-1-3">
              <label className="uk-form-label" htmlFor="crmOrderMethod">Order method:</label>
              <div className="uk-form-controls">
                <input
                  id="crmOrderMethod"
                  className="uk-input"
                  name="crmOrderMethod"
                  type="text"
                  placeholder="landing-page"
                  onChange={handleInputChange}
                  value={crmOrderMethod}
                />
              </div>
            </div>

            <div className="uk-width-1-1">
              <hr />
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="country">Страна (ISO):</label>
              <div className="uk-form-controls">
                <input
                  id="country"
                  className="uk-input"
                  name="country"
                  type="text"
                  placeholder="UZ"
                  onChange={handleInputChange}
                  value={country}
                />
              </div>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="language">Язык (ISO):</label>
              <div className="uk-form-controls">
                <input
                  id="language"
                  className="uk-input"
                  name="language"
                  type="text"
                  placeholder="RU"
                  onChange={handleInputChange}
                  value={language}
                />
              </div>
            </div>

            <div className="uk-width-1-1">
              <hr />
            </div>

            <div className="uk-width-1-1 uk-text-meta">
              <p>Настройки подарка для страницы допродаж. Будут использованы вместо значений по умолчанию.</p>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="giftSum">Стоимость допродаж для активации подарка:</label>
              <div className="uk-form-controls">
                <input
                  id="giftSum"
                  className="uk-input"
                  name="giftSum"
                  type="number"
                  min="0"
                  step="0.00001"
                  placeholder="Стоимость по ценам CRM (/1000)"
                  onChange={handleInputChange}
                  value={giftSum}
                />
              </div>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="giftId">Внешний ID подарка:</label>
              <div className="uk-form-controls">
                <input
                  id="giftId"
                  className="uk-input"
                  name="giftId"
                  type="text"
                  placeholder="gift-crm-external-id"
                  onChange={handleInputChange}
                  value={giftId}
                />
              </div>
            </div>

            <div className="uk-width-1-1">
              <hr />
            </div>

            <div className="uk-width-1-1 uk-text-meta">
              <p>Настройки бесплатной доставки для страницы допродаж. Будут использованы вместо значений по умолчанию.</p>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="freeDeliverySum">Стоимость допродаж для активации доставки:</label>
              <div className="uk-form-controls">
                <input
                  id="freeDeliverySum"
                  className="uk-input"
                  name="freeDeliverySum"
                  type="number"
                  min="0"
                  step="0.00001"
                  placeholder="Стоимость по ценам CRM (/1000)"
                  onChange={handleInputChange}
                  value={freeDeliverySum}
                />
              </div>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="freeDeliveryId">Внешний ID бесплатной доставки:</label>
              <div className="uk-form-controls">
                <input
                  id="freeDeliveryId"
                  className="uk-input"
                  name="freeDeliveryId"
                  type="text"
                  placeholder="free-delivery-crm-external-id"
                  onChange={handleInputChange}
                  value={freeDeliveryId}
                />
              </div>
            </div>

            <div className="uk-width-1-1">
              <hr />
            </div>

            <div className="uk-width-1-1 uk-text-meta">
              <p>Настройки для метода подтверждения заказа через TG-бот. Будут использованы вместо значений по умолчанию.</p>
            </div>

            <div className="uk-width-1-2">

              <label className="uk-form-label" htmlFor="approvementOptions">Статус:</label>
              <div className="uk-form-controls">
                <select
                  id="approvementOptions"
                  className="uk-select"
                  name="approvementOptions"
                  value={approvementOptions}
                  onChange={handleInputChange}
                >
                  <option value="0">Не активен</option>
                  <option value="1">Активен</option>
                </select>
              </div>
            </div>

            <div className="uk-width-1-2">
              <label className="uk-form-label" htmlFor="botApprovementDiscount">Скидка, %:</label>
              <div className="uk-form-controls">
                <input
                  id="botApprovementDiscount"
                  className="uk-input"
                  name="botApprovementDiscount"
                  type="number"
                  min="0"
                  step="0.1"
                  placeholder="5"
                  onChange={handleInputChange}
                  value={roundFloat(botApprovementDiscount * 100, 100) || ''}
                />
              </div>
            </div>

            <div className="uk-width-1-1">
              <hr />
            </div>

            {/* <div className="uk-width-1-1 uk-text-meta">
              <p>Рекомендовано использовать тестовый режим для отладки работы сайта, переключать в рабочий режим после успешного завершения тестов лендинга. <span className="uk-icon" style={{cursor: 'pointer'}} data-uk-icon="icon: info" data-uk-tooltip='title: Режимы работы сайта имеют следующие отличия: в <u>тестовом режиме</u> заказ в CRM и боте создается сразу после отправки формы лендинга, в <u>рабочем режиме</u> заказ создается с минимальной задержкой за счет обработки в фоне.'></span></p>
            </div>

            <div className="uk-width-1-1">
              <label className="uk-form-label" htmlFor="mode">Режим работы сайта:</label>
              <div className="uk-form-controls">
                <select
                  id="mode"
                  className="uk-select"
                  name="mode"
                  onChange={handleInputChange}
                  value={mode}
                  required={true}
                >
                  <option value="sync">Тестовый режим</option>
                  <option value="async">Рабочий режим</option>
                </select>
              </div>
            </div>

            <div className="uk-width-1-1">
              <hr />
            </div> */}


            <div className="uk-width-1-1" id="typeBox">

              <label className="uk-form-label" htmlFor="type">Тип сайта:</label>
              <div className="uk-form-controls">
                <select
                  id="type"
                  className="uk-select"
                  name="type"
                  value={type}
                  onChange={handleInputChange}
                  required={true}
                >
                  {renderTypesOptions()}
                </select>
              </div>
            </div>

            {(type == 'calculator' || type == 'variations') && (
              <div className="uk-width-1-1">
                <Variations
                  type={type}
                  typesMap={typesMap}
                  formula={[formula, setFormula]}
                />
              </div>
            )}

          </div>

        </div>


        <div className="uk-width-2-5">
          <div className="uk-width-1-1">
            <h2 className="uk-text-left">Допродажи</h2>
          </div>
          <Upsells
            upsells={[upsells, setUpsells]}
          />
        </div>


        <div className="uk-width-1-1">
          <button id='submitButton' type="submit" className="uk-button uk-button-primary uk-align-center custom-button-success" onSubmit={handleSubmit}>Сохранить</button>
        </div>

      </form>

      {(siteId > 0) && (modal)}

    </div>

  )
}

export default Site;
