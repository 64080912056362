import AbstractApi from "./AbstractApi";

class SitesCataloguesApi extends AbstractApi  {

  // Sites catalogues
  createMethod = 'dashboard/sites_catalogues/create';
  getFilterMethod = 'dashboard/sites_catalogues/get';
  getMethod = 'dashboard/sites_catalogues/{:id}/get';
  updateMethod = 'dashboard/sites_catalogues/{:id}/update';
  deleteMethod = 'dashboard/sites_catalogues/{:id}/delete';
  metaMethod = 'dashboard/sites_catalogues/meta';

  /*--------------------------------------------------------------------------*/
  /* Sites catalogues */

  async create(
    name,
    crmApiKey = false,
    crmBaseUri = false,
    crmOrderType = false,
    crmOrderMethod = false,
    crmShop = false,
    crmBuyerId = false,
    goods = [],
    country = '',
    language = '',
    internalName = '',
    url = '',
    catalogueOrderTotalMin = false,
    media = false,
    upsells = [],
    giftSum = false,
    giftId = '',
    freeDeliverySum = false,
    freeDeliveryId = '',
    botApprovementDiscount = false,
    approvementOptions = 0
  ) {

    this.requestMethod = this.createMethod;

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);
    this.requestData.append('name', name);

    if(crmApiKey !== false){
      this.requestData.append('crm[api_key]', crmApiKey);
    }

    if(crmBaseUri !== false){
      this.requestData.append('crm[base_uri]', crmBaseUri);
    }

    if(crmOrderType !== false){
      this.requestData.append('crm[order_type]', crmOrderType);
    }

    if(crmOrderMethod !== false){
      this.requestData.append('crm[order_method]', crmOrderMethod);
    }

    if(crmShop !== false){
      this.requestData.append('crm[shop]', crmShop);
    }

    if(crmBuyerId !== false){
      this.requestData.append('crm[buyer_id]', crmBuyerId);
    }

    goods.forEach((item, i) => {
      this.requestData.append(String('goods[').concat(i).concat('][type]'), item.type);
      this.requestData.append(String('goods[').concat(i).concat('][id]'), item.id);
    });

    this.requestData.append('country', country);
    this.requestData.append('language', language);

    this.requestData.append('internal_name', internalName);
    this.requestData.append('url', url);

    if(catalogueOrderTotalMin !== false){
      this.requestData.append('catalogue_order_total_min', catalogueOrderTotalMin);
    }

    if(media !== false){
      this.requestData.append('media', media);
    }

    upsells.forEach((item, i) => {
      this.requestData.append(`upsells[${i}][type]`, item.type);
      this.requestData.append(`upsells[${i}][id]`, item.id);
    });

    if(giftSum != false){
      this.requestData.append('gift[sum]', giftSum);
    }

    if(giftId != false){
      this.requestData.append('gift[id]', giftId);
    }

    if(freeDeliverySum != false){
      this.requestData.append('free_delivery[sum]', freeDeliverySum);
    }

    if(freeDeliveryId != false){
      this.requestData.append('free_delivery[id]', freeDeliveryId);
    }

    if(botApprovementDiscount !== false){
      this.requestData.append('bot_approvement_discount', botApprovementDiscount);
    }

    if(approvementOptions !== false){
      this.requestData.append('approvement_options', approvementOptions);
    }


    return await this.request();
  }


  async get(
    id = false,
    filterOrderBy = 'name',
    filterOrder = 'asc',
    filterSearch = false,
    filterCrmBuyerId = false,
    filterGoodsCategoryId = false,
    paginationPage = 1,
    paginationLimit = 50
  ) {

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    if(id){
      this.requestMethod = this.getMethod.replace('{:id}', id);
    } else {
      this.requestMethod = this.getFilterMethod;

      this.requestData.append('filter[order_by]', filterOrderBy);
      this.requestData.append('filter[order]', filterOrder);

      if(filterSearch){
        this.requestData.append('filter[search]', filterSearch);
      }

      if(filterCrmBuyerId != false){
        this.requestData.append('filter[crm_buyer_id]', filterCrmBuyerId);
      }

      if(parseInt(filterGoodsCategoryId) > 0){
        this.requestData.append('filter[goods_category_id]', filterGoodsCategoryId);
      }

      this.requestData.append('pagination[page]', paginationPage);
      this.requestData.append('pagination[limit]', paginationLimit);
    }

    return await this.request();
  }


  async update(
    id,
    name,
    crmApiKey = false,
    crmBaseUri = false,
    crmOrderType = false,
    crmOrderMethod = false,
    crmShop = false,
    crmBuyerId = false,
    goods = false,
    country = false,
    language = false,
    internalName = false,
    url = false,
    catalogueOrderTotalMin = false,
    media = false,
    deleteMedia = false,
    upsells = false,
    giftSum = false,
    giftId = false,
    freeDeliverySum = false,
    freeDeliveryId = false,
    botApprovementDiscount = false,
    approvementOptions = false
  ) {

    this.requestMethod = this.updateMethod.replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);
    this.requestData.append('name', name);

    if(crmApiKey !== false){
      this.requestData.append('crm[api_key]', crmApiKey);
    }

    if(crmBaseUri !== false){
      this.requestData.append('crm[base_uri]', crmBaseUri);
    }

    if(crmOrderType !== false){
      this.requestData.append('crm[order_type]', crmOrderType);
    }

    if(crmOrderMethod !== false){
      this.requestData.append('crm[order_method]', crmOrderMethod);
    }

    if(crmShop !== false){
      this.requestData.append('crm[shop]', crmShop);
    }

    if(crmBuyerId !== false){
      this.requestData.append('crm[buyer_id]', crmBuyerId);
    }

    if(goods !== false){
      goods.forEach((item, i) => {
        this.requestData.append(String('goods[').concat(i).concat('][type]'), item.type);
        this.requestData.append(String('goods[').concat(i).concat('][id]'), item.id);
      });
    }

    if(country !== false){
      this.requestData.append('country', country);
    }

    if(language !== false){
      this.requestData.append('language', language);
    }

    if(internalName !== false){
      this.requestData.append('internal_name', internalName);
    }

    if(url !== false){
      this.requestData.append('url', url);
    }

    if(catalogueOrderTotalMin != false || catalogueOrderTotalMin === ''){
      this.requestData.append('catalogue_order_total_min', catalogueOrderTotalMin);
    }

    if(media !== false){
      this.requestData.append('media', media);
    }

    if(deleteMedia !== false){
      this.requestData.append('delete_media', true);
    }

    if(upsells !== false){
      upsells.forEach((item, i) => {
        this.requestData.append(`upsells[${i}][type]`, item.type);
        this.requestData.append(`upsells[${i}][id]`, item.id);
      });
    }

    if(giftSum != false || giftSum === ''){
      this.requestData.append('gift[sum]', giftSum);
    }

    if(giftId != false){
      this.requestData.append('gift[id]', giftId);
    }

    if(freeDeliverySum != false || freeDeliverySum === ''){
      this.requestData.append('free_delivery[sum]', freeDeliverySum);
    }

    if(freeDeliveryId != false){
      this.requestData.append('free_delivery[id]', freeDeliveryId);
    }

    if(botApprovementDiscount !== false){
      this.requestData.append('bot_approvement_discount', botApprovementDiscount);
    }

    if(approvementOptions !== false){
      this.requestData.append('approvement_options', approvementOptions);
    }


    return await this.request();
  }


  async delete(id) {

    this.requestMethod = this.deleteMethod.replace('{:id}', id);

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    return await this.request();
  }


  async meta(id) {

    this.requestMethod = this.metaMethod;

    this.requestData = new FormData();
    this.requestData.append('token', this.authToken);

    return await this.request();
  }

}

export default SitesCataloguesApi;
